import { Vue, Component, Prop } from 'vue-property-decorator';
import FilePicker from '@/components/FilePicker'
import { IClient } from './User.definition'

@Component({
  components: {
    FilePicker
  }
})
export default class UserEdit extends Vue {
  @Prop({ default: null }) data: IClient;
  types = [{ id: 0, name: 'Купац' }, { id: 1, name: 'Добављач' }, { id: 2, name: 'Сарадник' }];

  render() {
    return <div>
      <bField label="Лого" horizontal>
        <file-picker />
      </bField>
      <hr />
      <bField label="Врста" message="Врста клијента" horizontal>
        <bSelect required vModel={this.data.tip}>
          {
            this.types.map(type =>
              <option
                value={type.id}
                key={type.id}
              >
                {type.name}
              </option>
            )
          }
        </bSelect>
      </bField>
      <bField label="Назив" message="Назив клијента" horizontal>
        <bInput vModel={this.data.ime} required />
      </bField>
      <bField label="Град" message="Име града" horizontal>
        <bInput placeholder="нпр. Београд" vModel={this.data.grad} />
      </bField>
      <bField label="Адреса" message="Адреса клијента" horizontal>
        <bInput vModel={this.data.adresa} />
      </bField>
      <bField label="Телефон" message="Контект телефон" horizontal>
        <bInput vModel={this.data.tel} vMask={'+### ## #######'} placeholder="+381 0## #######" />
      </bField>
      <bField label="Мобилни" message="мобилни телефон" horizontal>
        <bInput vModel={this.data.mob} vMask={'+### ## #######'} placeholder="+381 06# #######" />
      </bField>
      <bField label="Факс" message="Број факса" horizontal>
        <bInput vModel={this.data.fax} vMask={'+### ## #######'} placeholder="+381 0## #######" />
      </bField>
      <bField label="ПИБ" message="Порески идентификациони бр." horizontal>
        <bInput vModel={this.data.pib} vMask={'#########'} placeholder="000000000" min="9" />
      </bField>
      <bField label="МБ" message="Матични број" horizontal>
        <bInput vModel={this.data.matbr} vMask={'########'} placeholder="00000000" min="8" />
      </bField>
      <bField label="E-mail" message="Адреса електронске поште" horizontal>
        <bInput vModel={this.data.email} type="email" />
      </bField>
      <bField label="Шифра" message="Шифра делатности" horizontal>
        <bInput vModel={this.data.sifra} />
      </bField>
    </div>;
  }
}
