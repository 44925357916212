import api from '@/api'
import CardComponent from '@/components/CardComponent'
import { Vue, Component, Prop } from 'vue-property-decorator'
import TableGrip from '@/components/TableGrip'
import { modifiers as m } from "vue-tsx-support";

export interface IFColumn {
  field: string;
  label: string;
  type?: string;
  class?: string;
  width?: number;
  sortable?: boolean;
  searchable?: boolean;
  resizeable?: boolean;
  cellFormat?: () => string;
  actions?: Array<{
    icon: string;
    label?: string;
    tooltip?: string;
    class?: string;
    callback: () => void;
  }>
}

@Component({
  components: {
    CardComponent,
    TableGrip,
  },
})
export default class FTable extends Vue {
  @Prop({ default: '' }) title: string;
  @Prop({ default: '' }) icon: string;
  @Prop({ default: '' }) endpoint: string;
  @Prop({ default: 10 }) perPage: number;
  @Prop({ default: {} }) filter: any;
  @Prop({ default: [] }) columns: IFColumn[];
  @Prop({ default: false }) checkable: boolean;
  @Prop({ default: 'Табела је празна ...' }) zeroMessage: string;

  readonly numberFormatter = new Intl.NumberFormat('sr-RS', { minimumFractionDigits: 2 });

  data = [];
  isLoading = false;
  paginated = false;
  total = 100;
  page = 1;
  searchable = false;
  sortField = '';
  sortOrder = 'desc';
  defaultSortOrder = 'desc';
  checkedRows = [];
  perPageOptions = [10, 25, 50, 100];
  filters: any = {};
  footer: any[] = [];

  colWidth = 0;

  mounted() {
    this.loadAsyncData()
  }

  get activeFilters(): any {
    return { ...this.filters, ...this.filter };
  }

  onResize(width: number) {
    this.colWidth = width
  }

  onPageChange(page: number) {
    this.page = page
    this.loadAsyncData()
  }

  onPerPageChange() {
    this.loadAsyncData()
  }
  /*
* Handle sort event
*/
  onSort(field: string, order: any) {
    this.sortField = field
    this.sortOrder = order
    this.loadAsyncData()
  }

  onFilter(filters: any) {
    this.filters = filters;
    this.loadAsyncData();
  }

  toggleSearchable() {
    this.searchable = !this.searchable;
    if (!this.searchable) {
      Object.keys(this.filters).forEach((key) => {
        this.filters[key] = '';
      });
      this.loadAsyncData();
    }
  }

  private loadAsyncData() {
    if (!this.endpoint) return;
    this.isLoading = true
    api.get(this.endpoint, {
      page: this.page,
      per_page: this.perPage,
      sort_field: this.sortField,
      sort_order: this.sortOrder,
      filters: JSON.stringify(this.activeFilters)
    }).then(({ data, count, total }) => {
      this.isLoading = false
      if (count) {
        this.total = count;
        if (this.total > this.perPage) {
          this.paginated = true
        }
      }
      if (total) {
        this.footer = this.columns.map(column => {
          const value = total[column.field] ? this.numberFormatter.format(total[column.field]) : '';
          return <th>{value}</th>
        })
      }
      this.data = data || []
    })
      .finally(() => this.isLoading = false)
  }

  private renderColumn(column: any, row: any) {
    const value = column.cellFormat ? column.cellFormat(row, column.field) : row[column.field];
    switch (column.type) {
      case 'logo':
        return value
          ? <div class="image"><img src={value} class="is-rounded" /></div>
          : <bIcon icon="account" custom-size="default" />;
      case 'currency':
        return <span>{this.numberFormatter.format(value)}</span>;
      case 'date':
        return <span>{(new Date(value)).toLocaleDateString("ru-RU")}</span>;
      case 'customer':
        return <span>{value.ime}</span>;
      case 'actions':
        return <div class="buttons is-right">{
          column.actions.map((action: any, index: number) => {
            return <button
              type="button"
              key={index}
              class={['button', 'is-small', action.class]}
              title={action.tooltip}
              onClick={m.prevent(() => {
                const resp = action.callback(row)
                if (!!resp && typeof resp.then === 'function') {
                  resp.then((data: any) => {
                    if (!data.id || Object.keys(this.filter).some(k => row[k] != data[k])) {
                      this.loadAsyncData();
                    } else {
                      Object.keys(data).forEach(k => row[k] = data[k]);
                    }
                  })
                }
              })}
            >
              <bIcon icon={action.icon} size="is-small" />
            </button>
          })
        }</div>
      default:
        return <span>{value}</span>;
    }
  }

  private renderSearch(column: any, filters: any) {
    if (!column.searchable) return;
    switch (column.type) {
      case 'date':
        return <bDatepicker
          vModel={filters[column.field]}
          placeholder="Изаберите датум..."
          icon="calendar-today"
          size="is-small"
          trapFocus
          range />
      default:
        return <bInput
          vModel={filters[column.field]}
          placeholder="Претрага..."
          icon="magnify"
          size="is-small"
          type="search" />
    }
  }

  render() {
    return <section class="section is-main-section">
      <cardComponent
        class="has-table has-mobile-sort-spaced"
        title={this.title}
        icon={this.icon}
        header-icon={this.searchable ? 'filter' : 'filter-outline'}
        onHeader-icon-click={this.toggleSearchable}
      >
        <bTable
          checkedRows={this.checkedRows}
          checkable={this.checkable}
          loading={this.isLoading}
          striped={true}
          hoverable={true}
          scrollable={true}
          data={this.data}
          sort-icon="chevron-up"
          debounce-search={1000}
          paginated={this.paginated}
          backend-pagination
          total={this.total}
          per-page={this.perPage}
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          backend-sorting
          default-sort-direction={this.defaultSortOrder}
          default-sort={[this.sortField, this.sortOrder]}
          backend-filtering
          onPage-change={this.onPageChange}
          onSort={this.onSort}
          onFilters-change={this.onFilter}
        >
          {
            this.columns.map(column => {
              return <bTableColumn
                key={column.field}
                label={column.label}
                field={column.field}
                width={column.width}
                sortable={column.sortable}
                searchable={column.searchable && this.searchable}
                class={column.class}
                scopedSlots={{
                  default: (props: any) => this.renderColumn(column, props.row),
                  searchable: (props: any) => this.renderSearch(column, props.filters),
                }}
              >
              </bTableColumn>
            })
          }
          <template slot="footer">
            {
              this.footer
            }
          </template>
          <template slot="bottom-left">
            Ставки по страни:
            <bSelect
              placeholder="По страни"
              vModel={this.perPage}
              onInput={this.onPerPageChange}
            >
              {
                this.perPageOptions.map(perPage => {
                  return <option value={perPage} key={perPage}>
                    {perPage}
                  </option>
                })
              }
            </bSelect>
          </template>
          <section class="section" slot="empty">
            <div class="content has-text-grey has-text-centered">
              {
                this.isLoading
                  ? <div>
                    <p><bIcon icon="dots-horizontal" size="is-large" /></p>
                    <p>Преузимам податке...</p>
                  </div>
                  : <div>
                    <p><bIcon icon="emoticon-sad" size="is-large" /></p>
                    <p>{this.zeroMessage}</p>
                  </div>
              }
            </div>
          </section>
        </bTable>
      </cardComponent>
    </section>;
  }
}
