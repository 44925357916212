
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Resizable extends Vue {
  thElm: any;
  startOffset: number;

  constructor() {
    super();
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
  }

  inserted(el: any) {
    setTimeout(() => {
      el.querySelectorAll('table th').forEach((th: any) => {
        const grip = document.createElement('div');
        grip.className = 'grip';
        grip.addEventListener('mousedown', (e) => {
          this.thElm = th;
          this.startOffset = th.offsetWidth - e.pageX;
        });
        grip.addEventListener('click', (e) => {
          e.stopPropagation();
        });
        th.appendChild(grip);
      })

      document.addEventListener('mousemove', this.onMouseMove);
      document.addEventListener('mouseup', this.onMouseUp);
    }, 500);
  }

  onMouseMove(e: MouseEvent) {
    if (this.thElm) {
      this.thElm.style.width = this.startOffset + e.pageX + 'px';
    }
  }

  onMouseUp() {
    this.thElm = undefined;
  }

  unbind() {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
  }
}
