import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import UserEdit from '@/components/forms/UserEdit'
import { IClient } from '@/components/forms/User.definition'
import { Vue, Component } from 'vue-property-decorator'
import ResizableDirective from '@/components/ResizableDirective'
import FTable from '@/components/FTable'
import api from '@/api'
import { uiStore } from '@/store';

@Component({
  components: {
    HeroBar,
    TitleBar,
    CardComponent,
    FTable,
  },
  directives: {
    resizable: new ResizableDirective()
  }
})
export default class Invoices extends Vue {
  columns = [
    {
      label: 'Број',
      field: 'br',
      width: 128,
      cellFormat: (row) => `${row.br}/${row.promet.split('-')[0]}`,
      class: 'has-no-head-mobile is-image-cell',
    },
    {
      label: 'Клијент',
      field: 'customer',
      type: 'customer',
      searchable: true,
      width: 400,
    },
    {
      label: 'Датум',
      field: 'datum',
      type: 'date',
      searchable: true,
      sortable: true,
    },
    {
      label: 'Датум промета',
      field: 'promet',
      type: 'date',
      searchable: true,
      sortable: true,
    },
    {
      label: 'Износ',
      field: 'suma',
      type: 'currency',
      sortable: true,
    },
    {
      label: 'Плаћено',
      field: 'placeno',
      type: 'currency',
      sortable: true,
    },
    {
      label: 'Преостало',
      field: 'diff',
      type: 'currency',
      sortable: true,
      cellFormat: (row) => row.suma - row.placeno
    },
    {
      label: '',
      field: 'actions',
      type: 'actions',
      width: 102,
      actions: [
        {
          icon: 'account-edit',
          class: 'is-primary',
          tooltip: 'Измените клијента',
          callback: this.editInvoice
        },
        {
          icon: 'trash-can',
          class: 'is-danger',
          tooltip: 'Избришите клијента',
          callback: (row: any) => this.deleteModal(row.id)
        }
      ]
    }
  ];
  trashObject: any = {};

  type = 0;
  instanceId = 0;

  get titleStack() {
    return [
      'Админ',
      'Фактуре'
    ]
  }

  get trashObjectName() {
    if (this.trashObject) {
      return this.trashObject.name
    }

    return null
  }

  editInvoice(data: IClient) {
    this.$router.push(`invoice/${data.id}`);
  }

  addPanel() {
    uiStore.openSidePanel({
      title: 'Нови клијент',
      component: UserEdit,
      data: {},
      action: (value: IClient) => {
        return api.post('/invoice', value).then(() => {
          this.instanceId++
        })
      }
    });
  }

  deleteModal(id: number) {
    this.$buefy.dialog.confirm({
      message: 'Да ли сте сигурни да желите да избришете клијента?',
      onConfirm: () => api.delete(`/invoice/${id}`)
        .then(() => {
          this.$buefy.snackbar.open({
            message: 'Клијент је успешно избрисан',
            type: 'is-success',
            queue: false
          })
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            message: 'Грешка приликом брисања клијента!',
            type: 'is-danger',
            queue: false
          })
        })
        .finally(() => this.instanceId++)
    })
  }

  render() {
    return <div>
      <titleBar titleStack={this.titleStack} actions={[
        {
          icon: 'account-plus',
          class: 'is-primary',
          label: 'Нова фактура',
          callback: () => this.addPanel()
        }
      ]} />
      <bTabs position="is-centered" destroyOnHide={true} class="block" vModel={this.type}>
        <bTabItem label="Фактуре">
          <fTable
            title="Фактуре"
            zero-message="Још увек немате ни једног купаца ..."
            icon="library-books"
            columns={this.columns}
            endpoint="/invoice"
            key={this.instanceId}
            filter={{ tip: 1 }}
          >
          </fTable>
        </bTabItem>
        <bTabItem label="Профактуре">
          <fTable
            title="Профактуре"
            zero-message="Још увек немате добављача ..."
            icon="library-books"
            columns={this.columns}
            endpoint="/invoice"
            key={this.instanceId}
            filter={{ tip: 2 }}
          >
          </fTable>
        </bTabItem>
      </bTabs>
    </div>
  }
}
