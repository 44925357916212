import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import UserEdit from '@/components/forms/UserEdit'
import { IClient } from '@/components/forms/User.definition'
import { Vue, Component } from 'vue-property-decorator'
import ResizableDirective from '@/components/ResizableDirective'
import FTable from '@/components/FTable'
import api from '@/api'
import { uiStore } from '@/store';

@Component({
  components: {
    HeroBar,
    TitleBar,
    CardComponent,
    FTable,
  },
  directives: {
    resizable: new ResizableDirective()
  }
})
export default class Invoice extends Vue {
  invoice: any = {
    ownItem: []
  };

  mounted() {
    api.get(`/invoice/${this.$route.params.id}`).then(data => this.invoice = data);
  }

  get titleStack() {
    return [
      'Админ',
      'Фактурa',
      `бр. ${this.invoice.br}/${this.invoice.promet}`
    ]
  }

  editInvoice(): Promise<IClient> {
    return new Promise((resolve, reject) => {
      /*uiStore.openSidePanel({
        title: 'Уређивање клијента',
        component: UserEdit,
        data,
        action: (value: IClient) => {
          return api.post(`/invoice/${data.id}`, value).then((resp: IClient) => {
            resolve(resp);
          }).catch(reject)
        }
      });*/
    })
  }

  deleteModal(id: number) {
    this.$buefy.dialog.confirm({
      message: 'Да ли сте сигурни да желите да избришете клијента?',
      onConfirm: () => api.delete(`/invoice/${id}`)
        .then(() => {
          this.$buefy.snackbar.open({
            message: 'Клијент је успешно избрисан',
            type: 'is-success',
            queue: false
          })
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            message: 'Грешка приликом брисања клијента!',
            type: 'is-danger',
            queue: false
          })
        })
    })
  }

  render() {
    return <div>
      <titleBar titleStack={this.titleStack} actions={[
        {
          icon: 'account-plus',
          class: 'is-primary',
          label: 'Измена фактуре',
          callback: () => this.editInvoice()
        }
      ]} />
      <section class="section is-main-section">
        <cardComponent title="Test" headerIcon="settings">
          {
            this.invoice.ownItem.map(item =>
              <div>{item.name}</div>
            )
          }
        </cardComponent>
      </section>
    </div>
  }
}
