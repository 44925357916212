import { Vue, Component, Prop } from 'vue-property-decorator'
import { modifiers as m } from "vue-tsx-support";

@Component
export default class TableGrip extends Vue {
  @Prop({ default: 0 }) width: number;

  thElm: any;
  startOffset: number;

  constructor() {
    super();
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
  }

  bind() {
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
  }

  unbind() {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
  }

  onMouseDown(e: MouseEvent) {
    this.startOffset = this.width - e.pageX;
    this.bind();
  }

  onMouseMove(e: MouseEvent) {
    this.$emit('resize', Math.max(0, this.startOffset + e.pageX))
    if (this.thElm) {
      this.thElm.style.width = this.startOffset + e.pageX + 'px';
    }
  }

  onMouseUp() {
    this.unbind()
  }

  render() {
    return <div class="grip" ref="grip" onMousedown={this.onMouseDown} onClick={m.stop(m.prevent(() => { }))}></div>;
  }
}
