import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class FilePicker extends Vue {
  @Prop({ default: null }) accept: string;
  file = {
    name: ''
  };
  uploadPercent = 0;

  get buttonLabel() {
    return !this.file ? 'Pick a file' : 'Pick another file'
  }

  upload(file: File) {
    this.$emit('input', file)
    // Use this as an example for handling file uploads
    // let formData = new FormData()
    // formData.append('file', file)

    // axios
    //   .post(window.routeMediaStore, formData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data'
    //     },
    //     onUploadProgress: this.progressEvent
    //   })
    //   .then(r => {
    //
    //   })
    //   .catch(err => {
    //     this.$buefy.toast.open({
    //       message: `Error: ${err.message}`,
    //       type: 'is-danger'
    //     })
    //   })
  }

  progressEvent(progressEvent: ProgressEvent) {
    this.uploadPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
  }

  render() {
    return <bField class="file">
      <bUpload model={this.file} onInput={this.upload} accept={this.accept}>
        <a class="button is-primary">
          <bIcon icon="upload" custom-size="default"></bIcon>
          <span>{this.buttonLabel}</span>
        </a>
      </bUpload>
      {this.file && this.file.name && <span class="file-name">{this.file.name}</span>}
    </bField>;
  }
}
